import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Collapse from "react-bootstrap/Collapse";

import MenuItem from "./MenuItem";
import Logo from "../../assets/img/logo.png";
import { fetchHeaderDetails } from "../../actions/home";
import "../../scss/components/header/_header.scss";

const Header = () => {
  const [sticky, setSticky] = useState(false);
  const [menushow, setMenushow] = useState(false);
  const [languageOpen, setLanguageOpen] = useState(false);
  const navSticky = useCallback(() => {
    if (window.scrollY > 0) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  }, []);
  const dispatch = useDispatch();
  const header = useSelector((state) => state.home.header);

  useEffect(() => {
    dispatch(fetchHeaderDetails());
  }, [dispatch]);
  useEffect(() => {
    window.addEventListener("scroll", navSticky);
    return () => {
      window.removeEventListener("scroll", navSticky);
    };
  }, [navSticky]);
  return (
    <div className={`header-wrap ${sticky ? "shrink" : ""} `}>
      <div className="header">
        <div className="container">
          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="Symphony" width="168" height="78" />
            </Link>
          </div>
          {/* sidebar toggle button start */}
          <a href="https://shop.symphonylimited.com/" target="_blank" rel="noreferrer" className="e-shop-mobile">E-Shop</a>
          <button
            type="button"
            className="sidebar-toggle"
            onClick={() => setMenushow(!menushow)}
          >
            <FeatherIcon icon="menu" />
          </button>
          {/* sidebar toggle button end */}
          <div className={`menu-wrap ${menushow ? "menu-show" : ""}`}>
            <button
              type="button"
              className="sidebar-toggle"
              onClick={() => setMenushow(false)}
            >
              <FeatherIcon icon="x" />
            </button>
            <div className="menu">
              {header && header.length > 0 && (
                <ul>
                  {header
                    .filter((row) => row.menu_item_parent === "0")
                    .map((item, key) => (
                      <li key={key}>
                        <MenuItem header={header} item={item} />
                      </li>
                    ))}
                </ul>
              )}
            </div>
            <div className="language-cart">
              <div className="language-dd">
                <button
                  type="button"
                  onClick={() => setLanguageOpen(!languageOpen)}
                >
                  <FeatherIcon icon="globe" /> IN{" "}
                </button>
                <Collapse in={languageOpen}>
                  <div className="sub-menu">
                    <ul>
                      <li>
                        <a
                          href="http://en.keruilai.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          CN
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://symphonylimited.com.mx/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          MX
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.climatetechnologies.com.au/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          AU
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.bonairedurango.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          US
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://symphonyclimatizadores.com.br/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          BR
                        </a>
                      </li>
                    </ul>
                  </div>
                </Collapse>
              </div>
              {/* <div className="header-cart">
                <a
                  href="https://shop.symphonylimited.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FeatherIcon icon="shopping-cart" />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
