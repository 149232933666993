import {
  LOADER_SHOW,
  FOOTER_LOADER_SHOW,
  HEADER_LOADER_SHOW,
  FETCH_HOME_DETAILS_SUCCESS,
  FETCH_HEADER_DETAILS_SUCCESS,
  FETCH_FOOTER_DETAILS_SUCCESS,
} from "../actions/home";

const initialState = {
  is_loading: false,
  is_footer_loading: false,
  is_header_loading: false,
  home: {},
  footer: [],
  header: [],
};

export default (state, actions) => {
  if (state === undefined) {
    return initialState;
  }

  switch (actions.type) {
    case LOADER_SHOW:
      return {
        ...state,
        is_loading: actions.status,
      };
    case FOOTER_LOADER_SHOW:
      return {
        ...state,
        is_footer_loading: actions.status,
      };
    case HEADER_LOADER_SHOW:
      return {
        ...state,
        is_header_loading: actions.status,
      };
    case FETCH_HOME_DETAILS_SUCCESS:
      return {
        ...state,
        home: actions.results,
      };
    case FETCH_HEADER_DETAILS_SUCCESS:
      return {
        ...state,
        header: actions.results,
      };
    case FETCH_FOOTER_DETAILS_SUCCESS:
      return {
        ...state,
        footer: actions.results,
      };
    default:
      return state;
  }
};
