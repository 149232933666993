import React from "react";
import { Routes, Route } from "react-router-dom";
// import { RoutesPath } from "../routesPath";

// Pages
import Home from "./Home/Home";

export default function App() {
	return (
		<>
			<Home />
			<Routes>
				<Route path='/ab' element={<Home />}/>
			</Routes>
		</>
	);
}
