export const FETCH_HOME_DETAILS = "FETCH_HOME_DETAILS";
export const FETCH_HOME_DETAILS_SUCCESS = "FETCH_HOME_DETAILS_SUCCESS";
export const FETCH_HOME_DETAILS_ERROR = "FETCH_HOME_DETAILS_ERROR";
export const FETCH_HEADER_DETAILS = "FETCH_HEADER_DETAILS";
export const FETCH_HEADER_DETAILS_SUCCESS = "FETCH_HEADER_DETAILS_SUCCESS";
export const FETCH_HEADER_DETAILS_ERROR = "FETCH_HEADER_DETAILS_ERROR";
export const FETCH_FOOTER_DETAILS = "FETCH_FOOTER_DETAILS";
export const FETCH_FOOTER_DETAILS_SUCCESS = "FETCH_FOOTER_DETAILS_SUCCESS";
export const FETCH_FOOTER_DETAILS_ERROR = "FETCH_FOOTER_DETAILS_ERROR";
export const LOADER_SHOW = "LOADER_SHOW";
export const FOOTER_LOADER_SHOW = "FOOTER_LOADER_SHOW";
export const HEADER_LOADER_SHOW = "HEADER_LOADER_SHOW";

export const fetchHomeDetails = () => ({
  type: FETCH_HOME_DETAILS,
});

export const fetchHomeDetailsSuccess = (results) => ({
  type: FETCH_HOME_DETAILS_SUCCESS,
  results,
});

export const fetchHomeDetailsError = (errors) => ({
  type: FETCH_HOME_DETAILS_ERROR,
  errors,
});

export const fetchHeaderDetails = () => ({
  type: FETCH_HEADER_DETAILS,
});

export const fetchHeaderDetailsSuccess = (results) => ({
  type: FETCH_HEADER_DETAILS_SUCCESS,
  results,
});

export const fetchHeaderDetailsError = (errors) => ({
  type: FETCH_HEADER_DETAILS_ERROR,
  errors,
});

export const fetchFooterDetails = () => ({
  type: FETCH_FOOTER_DETAILS,
});

export const fetchFooterDetailsSuccess = (results) => ({
  type: FETCH_FOOTER_DETAILS_SUCCESS,
  results,
});

export const fetchFooterDetailsError = (errors) => ({
  type: FETCH_FOOTER_DETAILS_ERROR,
  errors,
});

export const loaderStatus = (status) => ({
  type: LOADER_SHOW,
  status,
});

export const headerLoaderStatus = (status) => ({
  type: HEADER_LOADER_SHOW,
  status,
});

export const footerLoaderStatus = (status) => ({
  type: FOOTER_LOADER_SHOW,
  status,
});
