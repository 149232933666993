import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import Collapse from "react-bootstrap/Collapse";

const MenuItem = ({ header, item }) => {
  const [open, setOpen] = useState(false);
  const investorClass = item.title === "INVESTORS" ? "investors-menu" : "";
  return (
    <>
      <a href={item.url}>{item.title}</a>
      {
        // eslint-disable-next-line eqeqeq
        header.filter((mobSub) => mobSub.menu_item_parent == item.ID).length >
          0 && (
          <>
            <button
              onClick={() => setOpen(!open)}
              type="button"
              className="toggle-btn"
            >
              {open ? (
                <FeatherIcon icon="chevron-up" />
              ) : (
                <FeatherIcon icon="chevron-down" />
              )}
            </button>
            <Collapse in={open}>
              <div className="sub-menu">
                <ul className={investorClass}>
                  {header
                    // eslint-disable-next-line eqeqeq
                    .filter((row) => row.menu_item_parent == item.ID)
                    .map((menu, key) => (
                      <li key={key}>
                        <a href={menu.url} target={menu.target} title={menu.post_excerpt}>{menu.title}</a>
                      </li>
                    ))}
                </ul>
              </div>
            </Collapse>
          </>
        )
      }
    </>
  );
};

export default MenuItem;
