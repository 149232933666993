import { put, call, takeLatest } from "redux-saga/effects";
import axios from "axios";

import {
  FETCH_HOME_DETAILS,
  FETCH_HEADER_DETAILS,
  FETCH_FOOTER_DETAILS,
  loaderStatus,
  headerLoaderStatus,
  footerLoaderStatus,
  fetchHomeDetailsSuccess,
  fetchHeaderDetailsSuccess,
  fetchFooterDetailsSuccess,
} from "../actions/home";
import { API_BASE_URL } from "../constants";

function* fetchHomeDetails() {
  yield put(loaderStatus(true));
  try {
    const results = yield call(
      axios.get,
      `${API_BASE_URL}/wp-json/custom/homedata?timestamp=${new Date().getTime()}`
    );
    yield put(loaderStatus(false));
    yield put(fetchHomeDetailsSuccess(results.data));
  } catch (error) {
    console.log("error: ", error);
    yield put(loaderStatus(false));
  }
}

function* fetchHeaderDetails() {
  yield put(headerLoaderStatus(true));
  try {
    const results = yield call(
      axios.get,
      `${API_BASE_URL}/wp-json/custom/menu/34`
    );
    yield put(headerLoaderStatus(false));
    yield put(fetchHeaderDetailsSuccess(results.data));
  } catch (error) {
    console.log("error: ", error);
    yield put(headerLoaderStatus(false));
  }
}

function* fetchFooterDetails() {
  yield put(footerLoaderStatus(true));
  try {
    const results = yield call(
      axios.get,
      `${API_BASE_URL}/wp-json/custom/footer`
    );
    yield put(footerLoaderStatus(false));
    yield put(fetchFooterDetailsSuccess(results.data));
  } catch (error) {
    console.log("error: ", error);
    yield put(footerLoaderStatus(false));
  }
}

export default function* () {
  yield takeLatest(FETCH_HOME_DETAILS, fetchHomeDetails);
  yield takeLatest(FETCH_FOOTER_DETAILS, fetchFooterDetails);
  yield takeLatest(FETCH_HEADER_DETAILS, fetchHeaderDetails);
}
